<!-- Finder Detalle Facturas Expedientes -->

<template>
  <div class="fras_libres_F" v-if="schema"> 
    <div class="contenedor"> 
                
        <!-- Cabecera -->
        <div class="cabecera" v-if="Entorno.header.header">       
          <base_Header 
            :Entorno="Entorno.header"
            @onEvent="event_Header">          
          </base_Header>
        </div>

      <v-sheet>
        <!-- Contenido -->
        <div class="contenedor"> 
         
          <!-- Filtro de Busqueda -->
          <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
            <div class="columna"> 
              <v-text-field
                style="width:40%"           
                v-bind="$input"
                v-model="schema.ctrls.txt.value"
                :label="schema.ctrls.txt.label"
                append-icon="mdi-magnify"
                @keypress.13="buscarFiltro"
                @click:append="buscarFiltro">
              </v-text-field>  

              <div style="display:flex;justify-content:space-between;">
                <div style="display:flex;width:40%">
                  <compfecha style="width:50%" :schema="schema.ctrls.fhd" :edicion=true />
                  <compfecha style="width:50%" :schema="schema.ctrls.fhh" :edicion=true />

                  <v-btn v-bind="$cfg.btn.busca" @click="buscarFiltro">
                    <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                  </v-btn>
                </div>

                <div style="display:flex;padding-right:20px;align-items:center">
                  <h3>{{ `Fras: ${this.records.length}` }}</h3>
                  <v-btn   
                    style="margin-left:20px;"                  
                    v-bind="$cfg.btra_cfg.standard"            
                    title="Recargar Facturas"                        
                    @click="refrescar">
                      <v-icon dark>{{ "mdi-refresh" }}</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>


          <!-- Nueva Factura de Venta -->
          <v-btn fab depressed x-small color="blue-grey lighten-1"
              title='Nueva Factura de Venta'
              @click="fusion('V')">
              <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
            <sub style="color:green;font-size:1rem;"> V</sub>
          </v-btn>


          <!-- Grid -->
          <virtual-list             
            class="fras_libres_list"
            style="height:515px;overflow-y: auto;padding-top:20px"                       
            :data-key="'id'"              
            :data-sources="records"             
            :data-component="item"
            :estimate-size="50">            
            
              <template v-slot:header>                
                <div class="conflex" style="color:#01579b">
                  <h5 style="width:10%;padding-left:20px">Acciones</h5>
                  <h5 style="width:10%">Nº Factura</h5>
                  <h5 style="width:10%">Fecha</h5>
                  <h5 style="width:10%">Importe</h5>
                  <h5 style="width:49%">Nombre</h5>
                  <h5 style="width:6%">Asiento</h5>
                  <h5 style="width:5%">Pagada</h5>
                </div>                
              </template>
          </virtual-list>         
        </div>     
      </v-sheet>
    </div>


      <!-- Ventana Presupuestos -->
      <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <div class="centrado">
            <exp_pres_M
              :padre="stName"
              :accion="accion"
              :accionRow="accionRow"
              :datos_complementarios="datos_nuevo_presupuesto"
              :auxRecord="{ record: JSON.parse(JSON.stringify(auxRecord)) }"
              :disparo="disparo"
              @onEvent="$event.accion==6? cerrar_exp_pres_M() : ''">            
            </exp_pres_M>                
          </div>
      </v-dialog>

  </div>
</template>


<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import fras_libres_F_item from '@/components/fras_libres_F_item';
  import plugs from "@/common/general_plugs";
  /* const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base"); */ 
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  /* const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base"); */
  /* const btracombi = () => plugs.groute("btracombi.vue", "comp"); */
  /*const btraextra = () => plugs.groute("btraextra_new.vue", "comp"); */
  // const fras_Recepcion = () => plugs.groute("fras_Recepcion.vue", "comp");  
  // const exp_Docs = () => plugs.groute("exp_Docs.vue", "comp");
  // // const fras_Exp_email = () => plugs.groute("fras_Exp_email.vue", "comp");
  // const fras_Digi = () => plugs.groute("fras_Digi.vue", "comp");
  // const exp_Cierres = () => plugs.groute("exp_Cierres.vue", "comp");
  const exp_pres_M = () => plugs.groute("fras_libres_M.vue", "comp"); 
//   const exp_M_presSup = () => plugs.groute("exp_M_presSup.vue", "comp"); 
  
  export default {
    name:'fras_libres_F',
    mixins: [mixinFinder],
    components: { 
      base_Header,
      /* base_Fgrid, */
      compfecha,
      exp_pres_M,
      /* btracombi, */      
        /* base_Fgrid, */
        /* btracombi,
        btraextra, */
        // fras_Recepcion,
        // exp_Docs,
        // fras_Exp_email,
        
        // exp_Cierres,
        // fras_Digi,
    //  exp_M_presSup
     },
    props: {
      padre: { type:String, default: ''},     
      disparoFinder: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },
  

    data() {
      return {  
        schema:{
          ctrls: {
            txt:{ f:'txt', label:'Búsqueda', value:''},
            fhd:{ f:'fhd', label:'Desde Fecha', value:'', comp:{              
              type:"fecha",
              format:"fh"
            }},

            fhh:{ f:'fhh', label:'Hasta Fecha', value:'', comp:{              
              type:"fecha",
              format:"fh"
            }},
          }
        },
        api:'fras_Exp_detalle',
        stName:'stLibresF',
        accion:"0",
        accionRow: {},   
        auxRecord: {},                 
        headers: {},        
        
        items_frasEmail: {},

        // para lanzar exp_pres_m
        datos_nuevo_presupuesto:{},
        componente_dinamico:null,
        modal:false,
        disparo:false,
        selected:{},

        // para lanzar exp_m_pressup
        disparo_sup:false,
        modalSup:false,

        // virtual scroller
        item: fras_libres_F_item,
       
      };
    },


    created() {
      this.$on('vsEvent', (evt) => {         
        this.event_btra_Row(evt);
      })
    },


    computed: {
      records() {
        return this.$store.state[this.stName].records;
      },

      // estado de la fra: COMPRA, VENTA, SUPLIDO
      COMPRA() { return this.accionRow.tipo_fra=="C"; },
      VENTA() { return this.accionRow.tipo_fra=="V"; },
      SUPLIDO() { return this.accionRow.tipo_fra=="S"; },
      /* CONTABILIZO() { 
        if (!this.selected.length) return false;
        if (Number(this.selected[0].fra_conta_asi)> 0) return false;
        return true;
      },  */           
    
      TRAMITACION() { return this.accionRow.tipo_fra=="W"; },
    },

    
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
       
        // configuro Entorno       
        this.Entorno.header.titulo="Facturas Libres";
        this.Entorno.grid.autoload= true;
        this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));
        
        this.headers= {
          header: [
            { text: "Acciones", value: "acciones", sortable: false, width: "5%", slot:true },
            { text: "Factura", value: "fra_doc", width: "10%" },
            { text: "Fecha", value: "fra_fh", width: "10%", filtro:'fecha' },
            { text: "Importe", value: "imp", width: "10%" },
            { text: "Cuenta", value: "cta_name_id", width: "45%" },
            { text: "Asiento", value: "fra_conta_asi", width: "10%" },
            { text: "Pagada", value: "pagada", width: "10%" }           
          ]
        }

        this.Entorno.grid.headers = this.set_headers();
        
        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'funec_M';
      },   


      ini_component() 
      {     
       this.get_records('iniLibres');
      },  


      // configuro estado botones
      botoneras_set() {  
        // VENTA
        if (this.app==1 && (this.VENTA || this.TRAMITACION) && !this.accionRow.fra_doc){
          this.btnSet("pasoFactura",{ view:true, disabled:false })
        }
      },


      // selecciono la primera linea del Grid, recalculo campos compra / venta
      // y filtro por tipo
      get_records_fin() {          
        //this.accionRow= this.miSt.records.length? this.miSt.records[0] : { id: 0 };     
      },



      // API para obtener el fichero (fra/doc) asociado al id recibido
      async ver_fichero() {
        let args = { tipo: 'fnc', accion: 'expediente', fn_args: { accion: "factura", id: this.accionRow.id, opc: 1 }};         
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // abro documento
        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");    
      },


      // búsqueda filtrada
      buscarFiltro() {
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
        
          return (this.schema.ctrls.fhd.value? elem.fra_fh>= this.schema.ctrls.fhd.value : elem) && 
            (this.schema.ctrls.fhh.value? elem.fra_fh<= this.schema.ctrls.fhh.value : elem) &&
            (
              elem.fra_doc.toLowerCase().includes(this.schema.ctrls.txt.value.toLowerCase()) ||
              elem.descrip.toLowerCase().includes(this.schema.ctrls.txt.value.toLowerCase()) ||
              elem.name_cta_id.toLowerCase().includes(this.schema.ctrls.txt.value.toLowerCase()) || 
              elem.fra_conta_asi.toLowerCase().includes(this.schema.ctrls.txt.value.toLowerCase()) ||           
              elem.imp.toLowerCase().includes(this.schema.ctrls.txt.value.toLowerCase())            
            )
        })});

      },


      // //
      // async contabilizo_factura() {
      //   let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
      //     accion:'conta',
      //     exp_id: this.accionRow.exp_id
      //   }};
      //   //console.log('args contabilizo_factura: ', args);
      //   let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
      //   //console.log('apiResult contabilizo_factura: ', apiResult); 
      //   let r=apiResult.r[1];
      //   this.$root.$alert.open('<u>FACTURAS CONTABILIZADAS <b>' + r[0] + '</b></u><br>' + r[1] + '<br>', 'success');
        
      //   this.ini_component();
      // },



      // ver módulo presupuesto/factura
      ver_fra() {      
        this.accion= "0";
        this.modal=true;
        this.disparo= !this.disparo;
      },


      /* async pasoFactura(){      
        this.$root.$alert.open("¿ Pasar a <b>FACTURA Y CONTABILIZAR</b> ?<br>" + 
            "<br>Se genera Numero de Factura y Fecha.<br>Despues de Aceptar se Emite Factura en PDF.<br>Recuerda que debes contabilizar el expediente.<br><br>"
            ,"confirm")
          .then((r)=>this.pasoFactura_respuesta(r)
        );      
      }, */
    
      /* async pasoFactura_respuesta(r){
        if (!r) return;
        let args={ 
          tipo:"fnc",
          accion: "facturas",
          fn_args:{accion:"factura_numero",
                  id:this.selected[0].id
                  }
        };

        console.log(args);
      }, */
       /*  await this.$store.dispatch("ajaxRequest", { args: args });
        this.btra_Mto({accion:0})
        
        this.pdf(); */
        //return
      


      async pdf(evt) {
        let args = { tipo: 'fnc', accion: 'expediente', fn_args: { accion: "factura", id:evt.row.id, opc: 1 }};         
        console.log('args pdf: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        console.log('apiResult pdf: ', apiResult);
        if (!apiResult.sql || apiResult.sql.error) {
          this.$root.$alert.open('No existe la factura', 'error');
          return;
        }

        // abro documento
        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");              
      },



      //
      async fusion(tipo_ope){
         
          //cab almacena valores de cabecera para pase
 
          let cab={tipo_ope:tipo_ope, suplido:false, tipo_cta:'0', cta_id:'0'};
          let lins=[];
          // lins almacena lineas de fusion
         


          // // Venta
          // if (cab.tipo_ope=="V"){
          //   cab.tipo_cta=this.auxRecord.callemp_tp
          //   cab.cta_id=this.auxRecord.callemp
          // } 
         
          // // COMPRA
          // if (cab.tipo_ope=="C" && funerariaN==0){
          //   //nada            
          // }

       
        this.datos_nuevo_presupuesto={cab,lins}
    
        this.accion= "1";
        this.modal= true;
        this.disparo= !this.disparo;
      },


      // recargo finder
      refrescar (){
        this.ini_component();
      },


      // entro al cerrar ventana de presupuestos expedientes
      cerrar_exp_pres_M() {        
        this.modal= false;
        this.ini_component();
      },


      //  entro al guardar un cierre
      cierreGuardado() {        
        this.$emit('cierreGuardado');        
      }


    },


  }
</script>